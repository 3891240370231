


















































































































import { Mixin_list } from "@/mixin";
import { api_meal_record, meal_record } from "@/http/meal_record";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import DatePicker from "@/components/datePicker/index.vue";
import Table from "@/components/table/index.vue";
import { api_store, store } from "@/http/store";
import StorageDb from "@/util/storage"

@Component({
  components: {
    Page,
    Table,
    DatePicker,
  },
})
export default class extends Mixin_list<meal_record>(api_meal_record.get_list) {
  filter = {
    store:StorageDb.getLocal('store_id'),
    create_time_before: "",
    create_time_after: "",
  };
  resetFilter = {
    ...this.filter,
  };
  columns = [
    {
      label: "ID",
      prop: "id",
      width: 80,
    },
    {
      label: "头像",
      slot: "avatar_url",
      prop: "avatar_url",
    },
    {
      label: "用户名",
      slot: "nickname",
      prop: "nickname",
    },
    {
      label: "充值金额",
      prop: "amount",
    },
    {
      label: "赠品",
      prop: "gifts",
    },
    {
      label: "订单号",
      prop: "order_no",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
  ];

  handleClick() {}
  // async handleClick( row:user){
  //     this.$confirm('确认升级吗?').then(async()=>{
  //         await api_user.edit({id:row.id,user_level:row.user_level === 1 ? 2 : row.user_level === 2 ? 3 : 0})
  //         this.get_list()
  //     })
  // }

  click_cell() {}
  // 获取头像
  get_avatar(value: string) {
    // 如果头像包含http，直接返回
    if (value.includes("http")) {
      return value;
    } else {
      return `https://images.xiyuebiaoju.com/${value}`;
    }
  }
  // 获取门店列表
  storeList: any[] = [];
  async get_store_list() {
    const res = await api_store.get_list({ page_size: 10000 });
    const list: any[] = [{ label: "全部", value: "" }];
    res.results.forEach((item: any) => {
      (item.value = item.id), (item.label = item.name);
    });
    this.storeList = list.concat(res.results);
  }
  onUpdateRange([startStr, endStr]: [string, string]) {
    this.filter.create_time_after = startStr;
    this.filter.create_time_before = endStr;
  }
  reset() {
    this.filter = { ...this.filter, ...this.resetFilter };
    (this.$refs.datePicker as Vue & { clearDates: () => void }).clearDates();
    this.find();
  }
  created() {
    this.get_store_list();
  }
}
