
import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface user{
    id:Id,
    phone:string
    superior:string
    superior_name:string
    create_time:string
    update_time:string
    user_type:string
    status:string
    amount:string
    nickname:string,
    avatar_url:string
}
export interface meal_record{
    id:Id,
    user:user
    create_time:string
    update_time:string
    amount:string
    status:string
    gifts:string
    order_no:string
    meal:string
}
export interface export_data{
    create_time_after:string,
    create_time_before:string
}


class HttpMealRecord extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<meal_record>>({...data})
	}

    get_info = (id:number) => {
        return this.get<meal_record>({},`/admin/meal_record/${id}/`)
    }
    // export = (data:export_data)=>{
    //     return this.get<string>({},`/admin/export/`,data)
    // }
}

export const api_meal_record =  new HttpMealRecord('/admin/meal_record/')
